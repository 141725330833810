const getConfig = require('next/config').default;
const { emptyObject } = require('../../constants');
const { isBrowser } = require('./isBrowser');

const getApiLink = ({ path, host, headers }) => {
  const { publicRuntimeConfig = emptyObject } = getConfig() || emptyObject;
  const {
    API_SSL, API_PORT, API_HOST, API_HOST_PUBLIC, API_HOST_PUBLIC_SUB, API_PATH,
  } = publicRuntimeConfig;

  const location = isBrowser
  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    ? window?.location
    : undefined;

  const publicHost = (
    headers?.host === API_HOST_PUBLIC_SUB
    || location?.host?.includes(API_HOST_PUBLIC_SUB)
  )
    ? API_HOST_PUBLIC_SUB
    : API_HOST_PUBLIC;

  const config = {
    ssl: API_SSL === 'true',
    port: API_PORT,
    hostname: host || (
      isBrowser
        ? publicHost
        : API_HOST
    ),
  };

  const {
    ssl, hostname, port,
  } = config;

  const protocol = `http${(ssl && isBrowser)
    ? 's'
    : ''}`;
  const apiPort = (ssl && isBrowser)
    ? 443
    : port;

  return `${protocol}://${hostname}:${apiPort}/${path || API_PATH}`;
};

module.exports = { getApiLink };

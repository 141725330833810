var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"67183a677d50da52d5d618093f5d8c61020b7955"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/frontend/67183a677d50da52d5d618093f5d8c61020b7955";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig: config } = getConfig();

(() => {
  if (!config.SENTRY_ENABLED) {
    // eslint-disable-next-line no-console
    console.log('[CLIENT]: ERROR MONITORING DISABLED');

    return;
  }

  init({
    dsn: config.SENTRY_DSN || 'https://0356fa3bdf254018a95a2c441048bfdc@o1077539.ingest.sentry.io/6080609',
    tracesSampleRate: 0,
    environment: config.APP_ENV,
    release: config.SENTRY_RELEASE,
    beforeSend: (event, hint) => {
      if (hint.originalException === 'JSBRIDGE TIMEOUT') {
        return null;
      }

      const userWithoutPersonalInfo = {
        ...event.user,
        email: undefined,
        username: undefined,
      };

      return {
        ...event,
        user: userWithoutPersonalInfo,
      };
    },
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      'firefoxSample',
      'chrome-extension', /* Chrome extensions */
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      'Can\'t find variable: fbq',
      'fbq is not defined',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Code editor errors
      'Could not find source file',
      'Debug Failure',
      'Cannot read properties of undefined (reading \'kind\')',
      'Cannot read properties of undefined (reading \'getStart\')',
      // OneSignal's SDK dependency error
      'Cannot read properties of undefined (reading \'toString\')',
      'Connection is disposed',
      'Connection got disposed',
      'monaco',
      'java.nio.file.NoSuchFileException',
      // User errors
      'Canceled',
      // False positive errors
      'cancelled',
      'Failed to fetch',
      'failed to fetch',
      'NetworkError',
      'Load failed',
      'Loading chunk',
      'Loading CSS chunk',
      'Failed to load the JS script of the agent',
      'api:4000',
      'NotAllowedError',
      'disposed',
      'monaco',
      'codemirror',
      'ResizeObserver loop completed with undelivered notifications.',
      'login_not_authorized',
      'domInteractive',
      'Manually abort route change.',
    ],
    ignoreUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /connect\.facebook\.net\/en_US\/fbevents\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /chrome-extension:\/\//,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Tiktok
      /analytics\.tiktok\.com/,
      /trello-smooth-dnd/,
      /solid-form/,
      /monaco-editor/,
      /codemirror/,
    ],
  });
})();

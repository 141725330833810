const DEFAULT_LOCALE = 'uk';

const FALLBACK_LOCALE = 'en';

const DEFAULT_SUB_DOMAIN = 'ua';

const DOMAIN_REGION = {
  uk: 'gb',
};

const REGION_DOMAIN = Object.entries(DOMAIN_REGION).reduce(
  (acc, [domain, region]) => (
    Object.assign(
      acc,
      { [region]: domain },
    )
  ),
  {},
);

const DOMAIN_SLUG = {
  UA: 'ua',
  UK: 'uk',
  PL: 'pl',
  IN: 'in',
  ZA: 'za',
  RO: 'ro',
  TR: 'tr',
  AM: 'am',
  AZ: 'az',
  CY: 'cy',
  GE: 'ge',
  IL: 'il',
  KZ: 'kz',
  KG: 'kg',
  MD: 'md',
  MN: 'mn',
  TJ: 'tj',
  TM: 'tm',
  UZ: 'uz',
  US: 'us',
  PH: 'ph',
  EU: 'eu',
  BR: 'br',
};

module.exports = {
  DEFAULT_LOCALE,
  FALLBACK_LOCALE,
  DEFAULT_SUB_DOMAIN,
  DOMAIN_REGION,
  REGION_DOMAIN,
  DOMAIN_SLUG,
};
